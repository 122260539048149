import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["watchList", "complete"];

  connect() {};
  async toggleCheckbox(event) {
    const watchList = this.watchListTarget.checked;
    const complete = this.completeTarget.checked;
    const checkbox = event.target;
    const videoId = checkbox.getAttribute("data-training-video-id");

    try {
      const response = await fetch("/user_training_videos", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        },
        body: JSON.stringify({
          user_training_video: {
            training_video_id: videoId,
            watch_list: watchList,
            complete: complete
          }
        })
      });

      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }

      console.log('Checkbox state saved successfully');
    } catch (error) {
      console.error("Error saving checkbox state:", error);
    }
  }
}