import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    connect() {
        const therapySessionKind = document.getElementById('therapy_session_kind');
        therapySessionKind.addEventListener('change', () => {
            const customType = document.getElementById('therapy_session_custom_kind_session');
            if(therapySessionKind.value === 'Other') {
                customType.style.display = 'block';
                customType.attributes.required = true;
            } else {
                customType.style.display = 'none';
                customType.attributes.required = false;
            }
        });
    }
}